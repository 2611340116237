var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-iam", attrs: { fluid: "", "pa-0": "" } },
    [
      _c(_setup.ResourcesRelationship, {
        attrs: {
          "allow-remove": "true",
          refreshKey: _setup.refreshKey,
          viaAccess: _setup.accessSelection,
        },
        on: {
          "add-relationship": _setup.onAddRelationship,
          "remove-relationship": _setup.onRemoveRelationship,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }